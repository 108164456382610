// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-all-tsx": () => import("./../../src/pages/all.tsx" /* webpackChunkName: "component---src-pages-all-tsx" */),
  "component---src-pages-map-tsx": () => import("./../../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-places-tsx": () => import("./../../src/templates/places.tsx" /* webpackChunkName: "component---src-templates-places-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

